<template>
  <div>
    <ucHead :indexNumber="0"></ucHead>
    <section class="uc-register">
      <div class="uc-content">
        <img src="@/assets/images/register-logo.png" class="logo" alt="">
        <div class="box">
          <h2>你好，欢迎登录</h2>
          <ul class="form">
            <li>
              <div class="txt"><img src="@/assets/images/register-icon-1.png" class="uc-icon16" alt="">请输入手机号/统一信用代码</div>
              <div class="con">
                <input type="text" class="uc-input" placeholder="请输入手机号/统一信用代码"  v-model="loginForm.account"   />
              </div>
            </li>
            <li>
              <div class="txt"><img src="@/assets/images/register-icon-2.png" class="uc-icon16" alt="">密码</div>
              <div class="con">
                <input type="password" class="uc-input" placeholder="请输入密码"   v-model="loginForm.password"  />
              </div>
            </li>
            <li>
              <div class="txt"><img src="@/assets/images/register-icon-2.png" class="uc-icon16" alt="">验证码</div>
              <div class="con">
                <input type="text" class="uc-input" placeholder="图片验证码"  v-model="loginForm.captchaCode"   />
                <div class="btn-code">
                  <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                </div>
              </div>
            </li>
          </ul>
          <div class="operate">
            <button class="uc-btn coffee" hover-class="none"  @click="handleLogin" v-loading="loading">登录</button>
            <div class="txt">还没有账号，<router-link class="uc-coffee" to="/register">注册新用户</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ucFoot></ucFoot>
  </div>
</template>
<script>
import {getCodeImg, register, sendSMSRegisterCode} from "@/api/login";

export default {
  name: 'login',
  components: {},
  data() {
    return {
      codeUrl: "",
      loginForm:{
        account:"",
        password:"",
        captchaCode:"",
        captchaUuid:""
      },
      loading:false

    }
  },
  created() {
    this.getCode();
  },
  methods: {

    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.loginForm.captchaUuid = res.data.uuid;
        }
      });
    },

    handleLogin(){

      if(this.loginForm.account=="" || this.loginForm.account==undefined){
        this.$message.error("请输入手机号/统一信用代码");
        return false
      }

      if(this.loginForm.password=="" || this.loginForm.password==undefined){
        this.$message.error("请输入密码");
        return false
      }

      if(this.loginForm.captchaCode=="" || this.loginForm.captchaCode==undefined){
        this.$message.error("请输入验证码");
        return false
      }


      this.loading = true;

      this.$store.dispatch("Login", this.loginForm).then(() => {
        this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
      }).catch(() => {
        this.loading = false;
        if (this.captchaEnabled) {
          this.getCode();
        }
      });



    },


    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/index';
        })
      }).catch(() => {});
    }


  },
}
</script>
