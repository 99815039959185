<template>
	<footer class="c-footer">
		<div class="uc-content">
			<div class="left">
				<div class="call">
					<img src="@/assets/images/footer-icon-1.png" class="uc-icon24" alt="">
					客服电话
				</div>
				<div class="number">173-1599-3188</div>
        <div class="number">180-3680-5132</div>
				<p>周一至周六 8:00-18:00</p>
				<p>公司地址：江苏省泰州市医药高新区明珠街道春风路16号</p>
				<router-link class="uc-btn coffee" to="">联系我们</router-link>
			</div>
			<div class="center">
				<ul class="menu">
					<li>
						<router-link class="item" to="/">
							<div class="name">首页</div>
							<p>Home</p>
						</router-link>
					</li>
					<li>
						<router-link class="item" to="">
							<div class="name">帮助中心</div>
							<p>Home</p>
						</router-link>
					</li>
					<li>
						<router-link class="item" to="/verify/index">
							<div class="name">保函查验</div>
							<p>Home</p>
						</router-link>
					</li>
				</ul>
				<div class="cpt">
					<p><span>Copyright © 2023</span> <span>江苏诚建非融资担保有限公司</span> <span>版权所有</span></p>
          <p><span>由<a href="https://www.cjbxjstz.com.cn/" target="_blank">长江财产保险股份有限公司泰州中心支公司</a></span> 提供技术支持</p>
					<p><a href="https://beian.miit.gov.cn"  target="_blank">
            苏ICP备2023002563号-1号
          </a></p>
				</div>
			</div>
			<div class="right">
				<div class="code-box">
					<img src="@/assets/images/footer-code.jpg" alt="" style="width: 150px">
					扫码添加客服微信
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
	export default {
		name: 'ucFoot',
		components: {},
		data() {
			return {}
		},
		mounted() {},
		methods: {},
	}
</script>
