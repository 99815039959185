<template>
  <div>
    <ucHead :index-number="1"></ucHead>
    <section class="uc-business">
      <div class="uc-content">
        <ucMenu></ucMenu>
        <div class="main">
          <section >
            <el-alert
                title="正在审核"
                type="warning"
                effect="dark"
                show-icon
                :closable="false"
                description="您的企业信息正在审核"
                v-if="this.$store.state.user.perfectStatus==0"
            >
            </el-alert>

            <el-descriptions class="margin-top" title="企业信息" :column="2"  border style="margin-top: 20px">
              <el-descriptions-item>
                <template slot="label">
                  公司名称
                </template>
                {{companyInfo.companyName}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  统一信用代码
                </template>
                {{companyInfo.creditCode}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  法人姓名
                </template>
                {{companyInfo.legalPerson}}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  法人身份证
                </template>
                {{companyInfo.legalPersonIdCard}}
              </el-descriptions-item>


              <el-descriptions-item>
                <template slot="label">
                  公司开户行
                </template>
                {{companyInfo.openBank}}
              </el-descriptions-item>


              <el-descriptions-item>
                <template slot="label">
                  公司银行账号
                </template>
                {{companyInfo.bankAccount}}
              </el-descriptions-item>


              <el-descriptions-item>
                <template slot="label">
                  纳税人识别号
                </template>
                {{companyInfo.taxpayerNumber}}
              </el-descriptions-item>


              <el-descriptions-item>
                <template slot="label">
                  公司联系电话
                </template>
                {{companyInfo.companyContactPhone}}
              </el-descriptions-item>


              <el-descriptions-item>
                <template slot="label">
                  公司地址
                </template>
                {{companyInfo.companyAddress}}
              </el-descriptions-item>


              <el-descriptions-item>
                <template slot="label">
                  联系人
                </template>
                {{companyInfo.contactPerson}}
              </el-descriptions-item>

            </el-descriptions>


            <el-descriptions class="margin-top" title="证件信息" :column="3"  border style="margin-top: 20px">
              <el-descriptions-item>
                <template slot="label">
                  营业执照
                </template>
                <image-preview :src="companyInfo.businessLicensePath" :width="50" :height="50"/>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  法人身份证正面
                </template>
                <image-preview :src="companyInfo.legalPersonIdCardFrontPath" :width="50" :height="50"/>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  法人身份证反面
                </template>
                <image-preview :src="companyInfo.legalPersonIdCardBackPath" :width="50" :height="50"/>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label">
                  开户许可证
                </template>
                <image-preview :src="companyInfo.openPermitPath" :width="50" :height="50"/>
              </el-descriptions-item>

            </el-descriptions>


          </section>
        </div>
      </div>
    </section>
    <ucFoot></ucFoot>
  </div>
</template>
<script>


import {getCompany} from "@/api/user";
import ImagePreview from "@/components/ImagePreview/index.vue";

export default {
  name: 'login',
  components: {
    ImagePreview
  },
  data() {
    return {
      companyInfo:{}
    }
  },
  mounted() {
    // this.$refs.myCascader[0].inputValue

    this.getCompanyInfo()


  },
  methods: {
    getCompanyInfo(){

      getCompany().then(res=>{
        console.log(res)
        if(res.code==200){
          this.companyInfo = res.data
        }
      });
    }


  },
}
</script>
