<template>
	<header class="c-header">
		<div class="uc-content">
			<router-link class="logo" to="">
				<img src="@/assets/images/logo.png" alt="">
			</router-link>
			<ul class="menu">
				<li :class="indexNumber==0?'on':''">
					<router-link class="item" to="/">首页</router-link>
				</li>
				<li :class="indexNumber==1?'on':''">
					<router-link class="item" to="/business/index">业务办理</router-link>
					<div class="sub-menu">
						<router-link class="sub-item" to="/business/index">投标保函</router-link>
						<router-link class="sub-item" to="">履约保函</router-link>
						<router-link class="sub-item" to="">农民工工资支付保函</router-link>
						<router-link class="sub-item" to="">投标低价差额保函</router-link>
						<router-link class="sub-item" to="">预付款保函</router-link>
						<router-link class="sub-item" to="">业主支付保函</router-link>
					</div>
				</li>
				<li :class="indexNumber==2?'on':''">
					<router-link class="item" to="">帮助中心</router-link>
				</li>
				<li :class="indexNumber==3?'on':''">
					<router-link class="item" to="/verify/index">保函查验</router-link>
				</li>

				<li :class="indexNumber==4?'on':''">
				  <router-link class="item" to="/loan/index">企业贷</router-link>
				</li>

			</ul>
			<div class="login-box" v-if="!this.$store.getters.token">
				<router-link class="uc-btn coffee" :to="{path: '/login' }">登录</router-link>
				<router-link class="uc-btn coffee-plain" :to="{path: '/register' }">注册</router-link>
			</div>
			<div class="user-box" v-if="this.$store.getters.token">
				<router-link class="hd" to="">
					<div class="face"><img src="@/assets/images/head.png" alt=""></div>
					<div class="name">{{this.$store.getters.name}}</div>
					<i class="uc-font uc-arrow-down2"></i>
				</router-link>
				<ul class="user-menu">
					<li>
						<router-link class="item" to="/user/index">
							<img src="@/assets/images/hd-icon-1-1.png" class="i1" alt="">
							<img src="@/assets/images/hd-icon-1-2.png" class="i2" alt="">
							个人中心
						</router-link>
					</li>
					<li>
						<a href="javascript:;" class="item" @click="logout">
							<img src="@/assets/images/hd-icon-2-1.png" class="i1" alt="">
							<img src="@/assets/images/hd-icon-2-2.png" class="i2" alt="">
							退出登录
						</a>
					</li>
				</ul>

			</div>
		</div>
	</header>
</template>
<script>
	export default {
		name: 'ucHead',
		components: {},
		props: {
			indexNumber: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {}
		},
		mounted() {
			// console.log(this.indexNumber)
		},
		methods: {
      async logout() {
        this.$confirm('确定注销并退出系统吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('FedLogOut').then(() => {
            location.href = '/';
          })
        }).catch(() => {});
      }
    },
	}
</script>

<style scoped>

.c-header .user-menu{

  top:80px;
  left: -15px;

}
.c-header .sub-menu{
  top:100px
}

</style>
