<template>
  <div>
    <ucHead :indexNumber="1"></ucHead>
    <section class="uc-order">
      <div class="uc-content">

        <ucMenu></ucMenu>

        <section  class="main">


          <el-table
          :data="tableData"
          border
          size="medium"
          style="width: 100%">
            <el-table-column
            prop="letterCode"
            label="保函编码"
            width="180">
            </el-table-column>
            <el-table-column
            prop="projectName"
            label="项目名称"
            width="180">
            </el-table-column>
            <el-table-column
            prop="projectNumber"
            label="项目代码">
            </el-table-column>


            <el-table-column
            prop="payStatus"
            label="审核状态">
              <template slot-scope="scope">
                <el-tag type="warning" size="small" v-if="scope.row.issueStatus==1">申请中</el-tag>
                <el-tag type="success" size="small" v-if="scope.row.issueStatus==2">已开具</el-tag>

              </template>
            </el-table-column>

            <el-table-column
            prop="payStatus"
            label="保函状态">
              <template slot-scope="scope">
                <el-tag type="danger" size="small" v-if="scope.row.cancelFlag==1">已作废</el-tag>
                <el-tag type="success" size="small" v-if="scope.row.cancelFlag==0">未作废</el-tag>
              </template>
            </el-table-column>


            <el-table-column
            fixed="right"
            label="操作"
            width="180px"
            >
              <template slot-scope="scope">
                <el-button @click="handleDetail(scope.row)" type="text" size="mini" >查看</el-button>


              </template>
            </el-table-column>

          </el-table>


        </section>


        <invoice-detail-modal ref="invoiceDetailModal" v-if="showInvoiceDetailModal"></invoice-detail-modal>







      </div>
    </section>
    <ucFoot></ucFoot>
  </div>
</template>
<script>
import {getOrderList,getLetterFile,getLetterDetail} from "@/api/letter";
import {invoiceList} from "@/api/invoice";
import orderDetailModal from "@/views/order/components/order-detail-modal";
import InvoiceDetailModal from "@/views/invoice/components/invoice-detail-modal.vue";
import moment from "moment";
export default {
  name: 'order',
  components: {
    orderDetailModal,
    InvoiceDetailModal
  },
  data() {
    return {
      current:1,
      tableData:[],
      queryForm:{
        pageNum:1,
        pageSize:10,
        projectNumber:undefined,
        projectName:undefined
      },
      total:0,
      downloadLoading:false,
      showInvoiceApplyForm:false,
      showInvoiceDetailModal:false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    moment,
    //列表
    getList(){
      invoiceList(this.queryForm).then(res=>{
        if(res.code==200){
          this.tableData = res.data.list
          this.total = res.data.total
        }
      })
    },
    //去支付
    handlePay(row){
      this.$router.push({path:"/business/index",query: {id: row.id,step:3}})
    },
    //查看保函详情
    handleDetail(row){

      this.showInvoiceDetailModal = true

      this.$nextTick(() => {
        this.$refs.invoiceDetailModal.init(row)
      })
    },

    handleDownload(row){
      this.downloadLoading = true
      let id = row.id
      getLetterFile(id).then(res=>{
        if(res.code==200){
          this.downloadLoading = false
          window.location.href = "/profile"+res.msg
        }else{
          this.downloadLoading = false
          this.$message.error(res.msg);
          return;
        }
      }).catch(()=>{
        this.downloadLoading = false
      })
    },

    handleSizeChange(e){

      this.queryForm.pageSize = e
      this.getList()

    },

    handleQuery(){
      this.getList()

    },
    resetQuery(){

      this.queryForm = {
        pageNum:1,
        pageSize:10,
      }
      this.getList()

    },
    handleInvoice(row){
      this.showInvoiceApplyForm = true
      let id = row.id
      getLetterDetail(id).then(res=>{
        if(res.code===200){
          this.$nextTick(() => {
            this.$refs.invoiceApplyForm.init(res.data)
          })
        }
      })

    },
    handleInvoiceDetail(row){

      this.showInvoiceDetailModal = true
      let id = row.id
      getLetterDetail(id).then(res=>{
        if(res.code===200){
          this.$nextTick(() => {
            this.$refs.invoiceDetailModal.init(res.data)
          })
        }
      })
    }



  },

}
</script>


<style scoped>

.uc-order .list .item{

  font-size: 14px;

}

</style>

