import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  },{
    path: '/business',
    name: 'business',
    component: () => import('../views/business.vue')
  },
  {
    path: '/user/index',
    name: 'userIndex',
    component: () => import('../views/user/index.vue')
  },
  {
    path: '/user/perfect',
    name: 'userPerfect',
    component: () => import('../views/user/perfect.vue')
  },

  {
    path: '/business/index',
    name: 'businessIndex',
    component: () => import('../views/business/index.vue')
  },
  {
    path: '/order/index',
    name: 'orderIndex',
    component: () => import('../views/order/index.vue')
  },
  {
    path: '/invoice/index',
    name: 'invoiceIndex',
    component: () => import('../views/invoice/index.vue')
  },
  {
    path: '/verify/index',
    name: 'verifyIndex',
    component: () => import('../views/verify/index.vue')
  },
	{
		path: '/loan/index',
		name: 'loanIndex',
		component: () => import('../views/loan/index.vue')
	},


]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
