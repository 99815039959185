<template>

  <div>

    <el-dialog title="发票详情" :visible.sync="dialogTableVisible" width="80%" :close-on-click-modal="false">


      <el-descriptions class="margin-top" title="项目信息" :column="3"  border  style="margin-top: 20px">

        <el-descriptions-item>
          <template slot="label">
            项目编号
          </template>
          {{projectInfo.number}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            项目名称
          </template>
          {{projectInfo.name}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            标段编号
          </template>
          {{projectInfo.sectionNumber}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            标段名称
          </template>
          {{projectInfo.sectionName}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            招标人
          </template>
          {{projectInfo.unit}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            招标人/受益人统一社会信用代码
          </template>
          {{projectInfo.unitCreditCode}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            省份
          </template>
          {{projectInfo.provinceName}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            城市
          </template>
          {{projectInfo.cityName}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            区县
          </template>
          {{projectInfo.areaName}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            项目类别
          </template>
          <span v-if="projectInfo.type==1">公共项目</span>
          <span v-if="projectInfo.type==0">其它项目</span>
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            开标时间
          </template>
          {{moment(projectInfo.openDate).format("YYYY-MM-DD")}} {{moment(projectInfo.openTime).format("HH:mm:ss")}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            投标截止日期
          </template>
          {{moment(projectInfo.bidExpiration).format("YYYY-MM-DD")}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            投标保证金额
          </template>
          {{projectInfo.bidDeposit}}元
        </el-descriptions-item>


      </el-descriptions>


      <el-descriptions class="margin-top" title="发票信息" :column="3"  border  style="margin-top: 20px">

        <el-descriptions-item>
          <template slot="label">
            发票类型
          </template>
          {{invoiceInfo.type==1?"普票":"专票"}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            申请状态
          </template>
          <span v-if="invoiceInfo.issueStatus==2">已开具</span>
          <span v-if="invoiceInfo.issueStatus==1">申请中</span>

        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            作废状态
          </template>
          <span v-if="invoiceInfo.cancelFlag==0">未作废</span>
          <span v-if="invoiceInfo.cancelFlag==1">已作废</span>

        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            申请人
          </template>
          {{invoiceInfo.receivePerson}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            申请人地址
          </template>
          {{invoiceInfo.receiveAddress}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            申请人手机
          </template>
          {{invoiceInfo.receivePhone}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            申请人邮箱
          </template>
          {{invoiceInfo.email}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            下载发票
          </template>

          <el-button @click="handleInvoiceDownload(invoiceInfo)" type="primary" size="mini" v-if="invoiceInfo.invoiceStatus==2">下载发票</el-button>

        </el-descriptions-item>


      </el-descriptions>


    </el-dialog>

  </div>

</template>

<script>

import moment from "moment";

import {detailInvoice} from "@/api/invoice";

export default {
  name: "invoice-detail-modal",
  data(){
    var checkEmail = (rules,value,cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if(regEmail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    return {
      loading:false,
      dialogTableVisible:false,
      id:undefined,
      orderInfo:{},
      projectInfo:{},
      companyInfo:{},
      invoiceInfo:{},
      form:{
        type:"1",
        title:undefined,
        tax:undefined,
        openBank:undefined,
        bankAccount:undefined,
        receivePhone:undefined,

      },
      rules:{
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {validator:checkEmail,trigger:'blur'}
        ],
      }

    }
  },

  methods:{
    moment,
    init(row){
      this.dialogTableVisible = true
      this.id = undefined
      this.id = row.id
      // console.log(row)

      this.getInvoiceDetail()
    },

    getInvoiceDetail(){

      detailInvoice(this.id).then(res=>{

        this.invoiceInfo = res.data
        this.orderInfo = res.data.letterModal
        this.projectInfo = res.data.projectModel

        console.log(res)

      })


    },

    cancel(){
      this.dialogTableVisible = false;
      this.$emit("cancel")
    },
    handleInvoiceDownload(row){

      window.location.href = row.filePath
    }


  }
}
</script>

<style scoped>

</style>
