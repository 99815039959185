<template>
  <div>
    <ucHead :indexNumber="0"></ucHead>
    <section class="uc-home-1">
      <div class="swiper-box">
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item,index) in 1" :key="index">
            <router-link class="item" to="">
              <img src="@/assets/images/home-banner-1.jpg" alt="">
            </router-link>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>
    <section class="uc-home-2">
      <div class="uc-content">
        <div class="g-title">
          <h2><span class="uc-coffee">OUR</span> SERVICE</h2>
          <h2><i></i>产品中心<i></i></h2>
          <img src="@/assets/images/title-arrow.png" alt="">
        </div>
        <ul class="list">
          <li>
            <img src="@/assets/images/home-icon-2-1.png" alt="">
            <h3>投标保函</h3>
            <p>BRAND STRATEGY CONSULTING</p>
            <div class="over-box">
              <div class="box">
                <h3>投标保函</h3>
                <p>BRAND STRATEGY CONSULTING</p>
                <div class="con">是在通过招标方式成交的工程建造和物资采购等项目中，投标方为响应招标方的要求向保证人申请出具的，保证投标方履行招标文件的一种保函。费率3‰</div>
                <router-link class="uc-btn" to="/business/index">立即办理</router-link>
              </div>
            </div>
          </li>


		  <li>
			<img src="@/assets/images/home-icon-2-6.png" alt="">
			<h3>投标贷</h3>
			<p>BRAND STRATEGY CONSULTING</p>
			<div class="over-box">
			  <div class="box">
				<h3>投标贷</h3>
				<p>BRAND STRATEGY CONSULTING</p>
				<div class="con">银行放款快、匹配率高、 服务费低、无需抵押。致力于解决中小微企业投标融资难、融资贵的问题。</div>
<!--				<router-link class="uc-btn" to="">立即办理</router-link>-->
				<a href="https://qy.dzbhsc.com/" class="uc-btn" target="_blank">立即办理</a>
			  </div>
			</div>
		  </li>


          <li>
            <img src="@/assets/images/home-icon-2-2.png" alt="">
            <h3>履约保函</h3>
            <p>INNOVATION IMC</p>
            <div class="over-box">
              <div class="box">
                <h3>履约保函</h3>
                <p>INNOVATION IMC</p>
                <div class="con">担保人应被保证人申请向工程承包项目中的业主或商品买卖中的买方出具的，保证被保证人严格按照合同约定全面和实际履行其合同责任义务的保函。</div>
                <router-link class="uc-btn" to="">立即办理</router-link>
              </div>
            </div>
          </li>


          <li>
            <img src="@/assets/images/home-icon-2-3.png" alt="">
            <h3>农民工工资支付保函</h3>
            <p>BRAND STRATEGY CONSULTING</p>
            <div class="over-box">
              <div class="box">
                <h3>农民工工资支付保函</h3>
                <p>BRAND STRATEGY CONSULTING</p>
                <div class="con">指建筑工程项目中为保证建设单位如期履行农民工工资支付义务，由担保人为建设单位向监管人提供的，保证建设单位如期履行农民工工资支付义务。</div>
                <router-link class="uc-btn" to="">立即办理</router-link>
              </div>
            </div>
          </li>
          <li>
            <img src="@/assets/images/home-icon-2-4.png" alt="">
            <h3>投标低价差额保函</h3>
            <p>BRAND STRATEGY CONSULTING</p>
            <div class="over-box">
              <div class="box">
                <h3>投标低价差额保函</h3>
                <p>BRAND STRATEGY CONSULTING</p>
                <div class="con">当投标人的投标报价低于投标成本预警价时，投标人应提供低价差额担保（担保的差额为等于或者大于投标成本预警价减去投标报价的整数）。</div>
                <router-link class="uc-btn" to="">立即办理</router-link>
              </div>
            </div>
          </li>
          <li>
            <img src="@/assets/images/home-icon-2-5.png" alt="">
            <h3>预付款保函</h3>
            <p>BRAND STRATEGY CONSULTING</p>
            <div class="over-box">
              <div class="box">
                <h3>预付款保函</h3>
                <p>BRAND STRATEGY CONSULTING</p>
                <div class="con">保证人为承包方向发包方提供的，在发包方预先支付一定数额款项以供承包方周转使用后，保证承包方在收到预付款后按施工合同约定专款专用保函。</div>
                <router-link class="uc-btn" to="">立即办理</router-link>
              </div>
            </div>
          </li>



<!--          <li>-->
<!--            <img src="@/assets/images/home-icon-2-6.png" alt="">-->
<!--            <h3>业主支付保函</h3>-->
<!--            <p>BRAND STRATEGY CONSULTING</p>-->
<!--            <div class="over-box">-->
<!--              <div class="box">-->
<!--                <h3>业主支付保函</h3>-->
<!--                <p>BRAND STRATEGY CONSULTING</p>-->
<!--                <div class="con">为保证业主履行合同约定的工程款支付业务，由担保人为业主向承包商担保的，保证业主支付工程款担保。业主签订工程建设合同同时，应向承包商提交业主主工程款支付担保。</div>-->
<!--                <router-link class="uc-btn" to="">立即办理</router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </li>-->



        </ul>
        <div class="operate">
          <router-link class="uc-btn" to="">展开更多 <i class="uc-font uc-arrow-down"></i></router-link>
        </div>
      </div>
    </section>
    <section class="uc-home-3">
      <div class="uc-content">
        <div class="g-title style-white">
          <h2><span class="uc-coffee">OUR</span> SERVICE</h2>
          <h2><i></i>公司简介<i></i></h2>
          <img src="@/assets/images/title-arrow-white.png" alt="">
        </div>
        <div class="box">
          <div class="img">
            <img src="@/assets/images/home-img-3.jpg" alt="">
          </div>
          <div class="right">
            <h3>江苏诚建非融资担保有限公司</h3>
            <h4>South-to-North Water Diversion Brand Building</h4>
            <div class="tips">电子招标一体化金融解决方案</div>
            <div class="con">
              江苏诚建非融资担保有限公司，是一家以工程担保为核心产业的专业担保机构，公司成立于2022年12月21日，注册资金10000万元人民币。主营业务包括：投标担保、预付款担保、工程履约担保、尾付款如约偿付担保、业务范围覆盖市政，房建，公路，水利，监理，园林，机电，消防，智能化，钢结构等多个工程建设板块。 我们的愿景：成为工程担保为核心产业的的领跑者。企业价值观：诚信经营，建功立业！
            </div>
            <div class="operate">
              <router-link class="uc-btn coffee-plain" to="">查看详情<i class="uc-font uc-arrow-right"></i></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="uc-home-4">
      <div class="uc-content">
        <div class="g-title">
          <h2><span class="uc-coffee">OUR</span> SERVICE</h2>
          <h2><i></i>为什么选择我们<i></i></h2>
          <img src="@/assets/images/title-arrow.png" alt="">
        </div>
        <div class="tips">我们一直致力于为您提供优质的服务</div>
        <ul class="list">
          <li>
            <img src="@/assets/images/home-icon-4-1.png" alt="">
            <h3>经验丰富</h3>
            <p>多年行业经验 业内口碑佳</p>
          </li>
          <li>
            <img src="@/assets/images/home-icon-4-2.png" alt="">
            <h3>价格合理</h3>
            <p>报价清晰头透明 不乱收费</p>
          </li>
          <li>
            <img src="@/assets/images/home-icon-4-3.png" alt="">
            <h3>服务高效</h3>
            <p>专业服务团队 效率高</p>
          </li>
          <li>
            <img src="@/assets/images/home-icon-4-4.png" alt="">
            <h3>专业团队</h3>
            <p>完善的服务体系 7*24小时客服</p>
          </li>
        </ul>
      </div>
    </section>
    <ucFoot></ucFoot>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: 'home',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {},
  methods: {},
}
</script>
