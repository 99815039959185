import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import "./assets/styles/main.css";

import './permission' // permission control


Vue.config.productionTip = false
import "./assets/styles/main.css";


import store from './store'



import plugins from './plugins' // plugins


Vue.use(plugins)


import Pagination from "@/components/Pagination";

Vue.component('Pagination', Pagination)



import ucHead from '@/components/ucHead'
Vue.component('ucHead', ucHead)
import ucFoot from '@/components/ucFoot'
Vue.component('ucFoot', ucFoot)

import ucMenu from '@/components/ucMenu'
Vue.component('ucMenu', ucMenu)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
