import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'


const whiteList = ['/','/index','/login', '/bind', '/register','/verify/index','/loan/index']

router.beforeEach((to, from, next) => {
  if (getToken()) {
    // to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
      store.dispatch('GetPerfectStatus').then(() => {

          if(store.state.user.perfectStatus==-1){
              if(to.path==="/user/perfect"){
                  next()
              }else{
                  next({ path: '/user/perfect' })
              }
          }else if(store.state.user.perfectStatus==0){
              //等待审核
              // next({ path: '/user/index' })

              if(to.path==="/user/index"){
                  next()
              }else{
                  next({ path: '/user/index' })
              }

          }else if(store.state.user.perfectStatus==1){
              //被拒绝，可以重新提交
              Message.error("您的申请被驳回，请重新提交！")
              if(to.path==="/user/perfect"){
                  next()
              }else{
                  next({ path: '/user/perfect' })
              }
          }else{
              //通过
              if (to.path === '/login' || to.path === '/register') {
                  next({ path: '/user/index' })
              } else {
                  // 判断当前用户是否已拉取完user_info信息
                  store.dispatch('GetInfo').then(() => {
                      next()

                  }).catch(err => {
                      store.dispatch('LogOut').then(() => {
                          Message.error(err)
                          next({ path: '/' })
                      })
                  })
              }

          }

      })


  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next('/login') // 否则全部重定向到登录页
    }
  }
})

router.afterEach(() => {
})
