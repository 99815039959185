<template>

  <div>

    <el-dialog title="查看保函信息" :visible.sync="dialogTableVisible" width="80%" :close-on-click-modal="false">



      <el-alert
          title="已作废"
          type="error"
          effect="dark"
          show-icon
          :closable="false"
          description="该保函已作废"
          v-if="orderInfo.cancelFlag==1"
      >
      </el-alert>

      <el-alert
          title="未支付"
          type="error"
          effect="dark"
          show-icon
          :closable="false"
          description="该订单还未支付"
          v-if="(orderInfo.payStatus==0 || orderInfo.payStatus==1) && orderInfo.cancelFlag==0"
      >
      </el-alert>

      <el-alert
          title="支付审核中"
          type="warning"
          effect="dark"
          show-icon
          :closable="false"
          description="该订单还未支付"
          v-if="orderInfo.payStatus==3 && orderInfo.cancelFlag==0"
      >
      </el-alert>

      <el-button @click="handleDownload(orderInfo)" type="primary" size="mini" v-if="orderInfo.payStatus==2 && orderInfo.cancelFlag==0" :loading="downloadLoading">下载保函</el-button>



      <el-descriptions class="margin-top" title="项目信息" :column="3"  border  style="margin-top: 20px">

        <el-descriptions-item>
          <template slot="label">
            项目编号
          </template>
          {{projectInfo.number}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            项目名称
          </template>
          {{projectInfo.name}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            标段编号
          </template>
          {{projectInfo.sectionNumber}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            标段名称
          </template>
          {{projectInfo.sectionName}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            招标人
          </template>
          {{projectInfo.unit}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            招标人/受益人统一社会信用代码
          </template>
          {{projectInfo.unitCreditCode}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            省份
          </template>
          {{projectInfo.provinceName}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            城市
          </template>
          {{projectInfo.cityName}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            区县
          </template>
          {{projectInfo.areaName}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            项目类别
          </template>
          <span v-if="projectInfo.type==1">公共项目</span>
          <span v-if="projectInfo.type==0">其它项目</span>
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            开标时间
          </template>
          {{moment(projectInfo.openDate).format("YYYY-MM-DD")}} {{moment(projectInfo.openTime).format("HH:mm:ss")}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            投标有效期
          </template>
          {{moment(projectInfo.bidExpiration).format("YYYY-MM-DD")}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            投标保证金额
          </template>
          {{projectInfo.bidDeposit}}元
        </el-descriptions-item>



      </el-descriptions>

      <el-descriptions class="margin-top" title="企业信息" :column="3"  border style="margin-top: 20px">
        <el-descriptions-item>
          <template slot="label">
            公司名称
          </template>
          {{companyInfo.companyName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            统一信用代码
          </template>
          {{companyInfo.creditCode}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            法人姓名
          </template>
          {{companyInfo.legalPerson}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            法人身份证
          </template>
          {{companyInfo.legalPersonIdCard}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            公司开户行
          </template>
          {{companyInfo.openBank}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            公司银行账号
          </template>
          {{companyInfo.bankAccount}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            纳税人识别号
          </template>
          {{companyInfo.taxpayerNumber}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            公司联系电话
          </template>
          {{companyInfo.companyContactPhone}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            公司地址
          </template>
          {{companyInfo.companyAddress}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            联系人
          </template>
          {{companyInfo.contactPerson}}
        </el-descriptions-item>

      </el-descriptions>


      <el-descriptions title="保函转账信息" class="margin-top"  :column="1"  border style="margin-top: 20px"  v-if="orderInfo.payType==1">
        <el-descriptions-item>
          <template slot="label">
            帐户名称
          </template>
          <span  >江苏诚建非融资担保有限公司</span>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            银行账号
          </template>
          3210200161010000022994
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            开户银行
          </template>
          江苏泰州农村商业银行股份有限公司经济开发区支行
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            备注
          </template>

          请在转账时备注保函编号：<el-tag size="medium" type="danger">{{orderInfo.code}}</el-tag>

        </el-descriptions-item>



      </el-descriptions>

      <el-row style="margin-top: 20px" v-if="bankPayInfo.submitStatus==1 && orderInfo.cancelFlag==0">

        <el-col :span="15" :offset="9">
          <el-button type="danger"   @click="submitPay" :loading="loading">我已确认转账，提交审核</el-button>
        </el-col>

      </el-row>

      <el-descriptions class="margin-top" title="转账信息" :column="3"  border style="margin-top: 20px" v-if="orderInfo.payType==1">
        <el-descriptions-item>
          <template slot="label">
            支付费用
          </template>
          {{bankPayInfo.payAmount}}元
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            用户提交审核时间
          </template>
          {{bankPayInfo.submitStatus==2?moment(bankPayInfo.payTime).format("YYYY-MM-DD HH:mm:ss"):"-"}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            审核时间
          </template>
          {{bankPayInfo.checkStatus!=0?moment(bankPayInfo.payTime).format("YYYY-MM-DD HH:mm:ss"):"-"}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            审核状态
          </template>

          <el-tag type="danger" v-if="bankPayInfo.submitStatus!=2">未提交</el-tag>

          <el-tag type="warning" v-if="bankPayInfo.submitStatus==2 && bankPayInfo.checkStatus==0">审核中</el-tag>
          <el-tag type="danger" v-if="bankPayInfo.submitStatus==2 && bankPayInfo.checkStatus==1">被拒绝</el-tag>
          <el-tag type="success" v-if="bankPayInfo.submitStatus==2 && bankPayInfo.checkStatus==2">通过</el-tag>

        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            审核备注
          </template>

          {{bankPayInfo.checkStatus==1?bankPayInfo.checkRejectMessage:"-"}}


        </el-descriptions-item>


      </el-descriptions>

      <el-descriptions class="margin-top" title="微信支付信息" :column="3"  border style="margin-top: 20px" v-if="orderInfo.payType==2">
        <el-descriptions-item>
          <template slot="label">
            支付费用
          </template>
          {{wxPayInfo.payAmount}}元
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            支付时间
          </template>
          {{wxPayInfo.payTime?moment(wxPayInfo.payTime).format("YYYY-MM-DD HH:mm:ss"):'-'}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            订单号
          </template>
          {{wxPayInfo.tradeNo}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            微信交易单号
          </template>
          {{wxPayInfo.transactionNo}}
        </el-descriptions-item>

      </el-descriptions>





      <el-descriptions class="margin-top" title="发票信息" :column="3"  border  style="margin-top: 20px" v-if="orderInfo.invoiceStatus==2">

        <el-descriptions-item>
          <template slot="label">
            发票类型
          </template>
          {{invoiceInfo.type==1?"普票":"专票"}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            申请状态
          </template>
          <span v-if="invoiceInfo.issueStatus==2">已开具</span>
          <span v-if="invoiceInfo.issueStatus==1">申请中</span>

        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            作废状态
          </template>
          <span v-if="invoiceInfo.cancelFlag==0">未作废</span>
          <span v-if="invoiceInfo.cancelFlag==1">已作废</span>

        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            申请人
          </template>
          {{invoiceInfo.receivePerson}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            申请人地址
          </template>
          {{invoiceInfo.receiveAddress}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            申请人手机
          </template>
          {{invoiceInfo.receivePhone}}
        </el-descriptions-item>


        <el-descriptions-item>
          <template slot="label">
            申请人邮箱
          </template>
          {{invoiceInfo.email}}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            下载发票
          </template>

          <el-button @click="handleInvoiceDownload(invoiceInfo)" type="primary" size="mini" v-if="orderInfo.invoiceStatus==2">下载发票</el-button>


        </el-descriptions-item>





      </el-descriptions>



    </el-dialog>

  </div>

</template>

<script>

import moment from "moment";
import {getLetterFile, submitBankPay} from "@/api/letter";

export default {
  name: "order-detail-modal",
  data(){
    return {
      downloadLoading:false,
      loading:false,
      dialogTableVisible:false,
      orderInfo:{},
      projectInfo:{},
      companyInfo:{},
      wxPayInfo:{},
      bankPayInfo:{},
      invoiceInfo:{}


    }
  },
  methods:{
    moment,
    init(row){

      this.dialogTableVisible = true

      this.orderInfo = row
      this.projectInfo = row.project
      this.companyInfo = row.user

      if(row.payType===1){
        this.bankPayInfo = row.bankPay
      }else if(row.payType===2){
        this.wxPayInfo = row.wxPay
      }
      this.invoiceInfo = row.invoiceModel

      console.log(row)

    },
    submitPay(){

      this.$confirm('您确认已经转账完成，并且已经备注保函编号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.loading = true

        submitBankPay({letterId:this.orderInfo.id}).then(res=>{

          if(res.code===200){

            this.loading = false
            this.$message.success("提交成功！请等待后台处理")
            this.dialogTableVisible = false

          }else{
            this.loading = false
            this.$message.error(res.msg)

          }

        }).catch(()=>{
          this.loading = false
          this.$message.error("提交异常！请重试！")

        })


      }).catch(() => {
        this.$message({
          type: 'error',
          message: '已取消支付'
        });
      });


    },

    handleDownload(row){

      this.downloadLoading = true

      let id = row.id
      getLetterFile(id).then(res=>{
        console.log(res)

        if(res.code==200){

          this.downloadLoading = false
          window.location.href = "/profile"+res.msg

        }else{

          this.downloadLoading = false
          this.$message.error(res.msg);
          return;

        }

      }).catch(()=>{

        this.downloadLoading = false


      })

    },

    handleInvoiceDownload(row){

      window.location.href = row.filePath
    }


  }
}
</script>

<style scoped>

</style>
