<template>
  <div>
    <ucHead :indexNumber="3"></ucHead>
    <section class="uc-verification-1">
      <div class="uc-content">
        <h2>保函查验</h2>
        <div class="box">
          <div class="hd">
            <div class="search-box">
              <input type="text" class="uc-input" placeholder="请输入保函编码" v-model="code" placeholder-class="uc-phColor" />
              <button class="uc-btn coffee" @click="handleQuery" v-loading="loading">保函查验</button>
            </div>
            <div class="tips">
              保函查询码是一串字符串，包括数字与字母您可在电子保函中找到它，<br>填写在上面 例: JO-SBM-386-U1L
            </div>
          </div>

          <div class="bd" v-if="letterInfo.status==2 ">
            <div class="txt" style="color: red">
              <img src="@/assets/images/icon-error.png" alt="">查询失败
            </div>
            <div class="con" style="color: red">
              保函不存在或者保函未生效！
            </div>
          </div>

          <div class="bd" v-if="letterInfo.status!='' && letterInfo.status!=null && letterInfo.status!=2 ">
            <div class="txt">
              <img src="@/assets/images/icon-ok.png" alt="">查询成功
            </div>
            <div class="con">
              查询到保函编号{{letterInfo.code}}的保函/保单
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="uc-verification-2" >
      <div class="uc-content" v-if="letterInfo.status!='' && letterInfo.status!=null && letterInfo.status!=2">
        <ul class="info">
          <li>
            <span class="txt">业务编号</span>
            <div class="con">{{letterInfo.institutionCode?letterInfo.institutionCode:'-'}}</div>
          </li>
          <li>
            <span class="txt">状态</span>
            <div class="con">

              <el-tag type="success" v-if="letterInfo.status===1">正常履约</el-tag>
              <el-tag type="success" v-if="letterInfo.status===3">正常履约，未到生效日期</el-tag>
              <el-tag type="danger" v-if="letterInfo.status===4">已过期</el-tag>
              <el-tag type="danger" v-if="letterInfo.status===5">已作废</el-tag>
              <el-tag type="danger" v-if="letterInfo.status===6">已退保</el-tag>

            </div>
          </li>
          <li>
            <span class="txt">项目名称</span>
            <div class="con">{{letterInfo.projectName?letterInfo.projectName:"-"}}</div>
          </li>
          <li>
            <span class="txt">项目编号</span>
            <div class="con">{{letterInfo.projectNumber?letterInfo.projectNumber:'-'}}</div>
          </li>
          <li>
            <span class="txt">受益人/招标人/被保险人</span>
            <div class="con">{{letterInfo.unitName?letterInfo.unitName:'-'}}</div>
          </li>
          <li>
            <span class="txt">承保人/保险人</span>
            <div class="con">{{letterInfo.institutionName?letterInfo.institutionName:'-'}}</div>
          </li>
          <li>
            <span class="txt">被保证人/投保人/投标人</span>
            <div class="con">{{letterInfo.proposerName?letterInfo.proposerName:'-'}}</div>
          </li>
          <li>
            <span class="txt">保额</span>
            <div class="con">{{letterInfo.businessAmount?letterInfo.businessAmount:'-'}}元</div>
          </li>
          <li>
            <span class="txt">第三方保函/保单号码</span>
            <div class="con">{{letterInfo.code?letterInfo.code:'-'}}</div>
          </li>
        </ul>
        <div class="detail">
          <img src="@/assets/images/verification-icon.png" class="uc-icon36" alt="">
          <div class="con">
            <p>查询结果说明:</p>
            <p>1、当查询结果中状态为“正常履约”状态时，该保函/保单为合法生效状态</p>
            <p>2、查询结果中状态为“履约结束“状态时，说明该保函/保单合法但已超过了约定的有效期。</p>
            <p>3、项目开标的，承保人、投标人等信息为隐藏状态。开标时间后。全部信息公开.</p>
          </div>
        </div>
      </div>
    </section>
    <ucFoot></ucFoot>
  </div>
</template>
<script>

import {verifyLetter} from "@/api/letter";

export default {
  name: 'verification',
  components: {},
  data() {
    return {
      loading:false,
      code:"",
      letterInfo:{
        status:undefined
      }
    }
  },
  mounted() {},
  methods: {

    handleQuery(){

      if(this.code=="" || this.code.length<=8){
        this.$message.error("请输入正确的保函编码！");
        return false;
      }

      if(!this.loading){

        let _this = this

        initGeetest4({
          captchaId: 'b67b32cc7a29cedd6aed1f4042149a0f',
          product:"bind",
          nativeButton:{
            width:"200px",
            height:"100px"
          }
        },function (captcha) {
          // captcha为验证码实例
          // captcha.appendTo("#test");// 调用appendTo将验证码插入到页的某一个元素中，这个元素用户可以自定义
          captcha.onReady(function(){

            _this.loading = true

            //your code
          }).onSuccess(function(){
            //your code
            var result = captcha.getValidate();

            let postdata = {
              'code':_this.code,
              'captchaOutput':result.captcha_output,
              'genTime':result.gen_time,
              'lotNumber':result.lot_number,
              'passToken':result.pass_token
            }
            verifyLetter(postdata).then(res=>{
              if(res.code==200){
                _this.loading = false
                // _this.$message.success("查询成功！")
                _this.letterInfo = res.data


              }else {
                _this.$message.error("查询失败！")
                _this.loading = false
                return false
              }
              // console.log(res)
            }).catch(()=>{

              _this.loading = false

            })


          }).onError(function(){
            //your code
            _this.loading = false

          }).onClose(function (){
            _this.loading = false
          })
          captcha.showCaptcha();

        });
      }


    }


  },
}
</script>
