<template>
  <div>
    <ucHead :indexNumber="1"></ucHead>
    <section class="uc-order">
      <div class="uc-content">

        <ucMenu></ucMenu>

        <section  class="main">
          <div class="form-box">
            <el-form ref="queryForm" :model="queryForm" label-width="100px" inline>
              <el-form-item label="项目编号：">
                <el-input v-model="queryForm.projectNumber"></el-input>
              </el-form-item>
              <el-form-item label="项目名称：">
                <el-input v-model="queryForm.projectName"></el-input>
              </el-form-item>
              <el-form-item label="标段编号：">
                <el-input v-model="queryForm.sectionNumber"></el-input>
              </el-form-item>
              <el-form-item label="标段名称：">
                <el-input v-model="queryForm.sectionName"></el-input>
              </el-form-item>
              <el-form-item label="招标人：">
                <el-input v-model="queryForm.unit"></el-input>
              </el-form-item>
              <el-form-item label="开标时间：">
                <el-date-picker v-model="queryForm.OpenDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-form>
            <div class="operate">
              <button class="uc-btn coffee" @click="handleQuery">搜索</button>
              <button class="uc-btn coffee-plain" @click="resetQuery">重置</button>
            </div>
          </div>
          <ul class="list">
            <li v-for="(item,index) in tableData" :key="index">
              <div class="hd">
                <div class="left">
                  <h3>{{item.projectName}}</h3>
                  <p>项目代码：{{item.projectNumber}}</p>
                </div>
                <div class="right">
                  <button class="uc-btn coffee-plain" @click="handleDetail(item)">查看</button>
                  <button class="uc-btn coffee-plain" hover-class="none" @click="handlePay(item)"  v-if="(item.payStatus==0 || item.payStatus==1 || item.payStatus==3) && item.cancelFlag==0">去支付</button>
                  <button class="uc-btn coffee-plain" hover-class="none" @click="handleInvoice(item)" v-if="item.payStatus==2 && item.cancelFlag==0 && item.invoiceStatus==0">申请发票</button>
                  <button class="uc-btn coffee-plain" hover-class="none" @click="handleDownload(item)" type="text" size="mini" v-if="item.payStatus==2 && item.cancelFlag==0" :loading="downloadLoading">下载保函</button>
<!--                  <button class="uc-btn coffee-plain" hover-class="none">退保</button>-->
                </div>
              </div>
              <div class="bd">
                <div class="item" style="width:30%;"><img src="@/assets/images/order-icon-2-1.png" alt="">保证金金额：¥{{item.businessAmount}}</div>
                <div class="item" style="width:30%;"><img src="@/assets/images/order-icon-2-1.png" alt="">保函编号：{{item.code}}</div>
                <div class="item" style="width:20%;">审核状态：
                  <el-tag type="warning" size="small" v-if="item.checkStatus==0">审核中</el-tag>
                  <el-tag type="danger" size="small" v-if="item.checkStatus==1">被拒绝</el-tag>
                  <el-tag type="success" size="small" v-if="item.checkStatus==2">已通过</el-tag>
                </div>
                <div class="item" style="width:20%;">支付方式：

                  <el-tag type="success" size="small" v-if="item.payType==1">对公转账</el-tag>
                  <el-tag type="success" size="small" v-if="item.payType==2">微信支付</el-tag>
                  <el-tag type="danger" size="small" v-if="item.payType==null">未选择</el-tag>

                </div>
                <div class="item" style="width:30%;"><img src="@/assets/images/order-icon-2-1.png" alt="">出函：江苏诚建非融资担保有限公司</div>
                <div class="item" style="width:30%;"><img src="@/assets/images/order-icon-2-1.png" alt="">申请时间：{{moment(item.createTime).format("YYYY-MM-DD HH:mm:ss")}}</div>
                <div class="item" style="width:20%;">保函状态：
                  <el-tag type="danger" size="small" v-if="item.cancelFlag==1">已作废</el-tag>
                  <el-tag type="success" size="small" v-if="item.cancelFlag==0">未作废</el-tag>
                </div>
                <div class="item" style="width:20%;">支付状态：

                  <el-tag type="danger" size="small" v-if="item.payStatus==0 || item.payStatus==1">未支付</el-tag>
                  <el-tag type="danger" size="small" v-if="item.payStatus==3">支付审核中</el-tag>
                  <el-tag type="success" size="small" v-if="item.payStatus==2">支付成功</el-tag>

                </div>
              </div>
            </li>
          </ul>
          <el-pagination
              @size-change="handleSizeChange"
              :page-size="queryForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </section>



        <order-detail-modal ref="orderDetailModal" ></order-detail-modal>

        <invoice-apply-form ref="invoiceApplyForm" @cancel="showInvoiceApplyForm=false" v-if="showInvoiceApplyForm"></invoice-apply-form>



      </div>
    </section>
    <ucFoot></ucFoot>
  </div>
</template>
<script>
import {getOrderList,getLetterFile,getLetterDetail} from "@/api/letter";
import orderDetailModal from "@/views/order/components/order-detail-modal";
import InvoiceApplyForm from "@/views/invoice/components/invoice-apply-form";
import InvoiceDetailModal from "@/views/invoice/components/invoice-detail-modal.vue";
import moment from "moment";
export default {
  name: 'order',
  components: {
    orderDetailModal,
    InvoiceApplyForm,
    InvoiceDetailModal
  },
  data() {
    return {
      current:1,
      tableData:[],
      queryForm:{
        pageNum:1,
        pageSize:10,
        projectNumber:undefined,
        projectName:undefined
      },
      total:0,
      downloadLoading:false,
      showInvoiceApplyForm:false,
      showInvoiceDetailModal:false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    moment,
    //列表
    getList(){
      getOrderList(this.queryForm).then(res=>{
        if(res.code==200){
          this.tableData = res.data.list
          this.total = res.data.total
        }
      })
    },
    //去支付
    handlePay(row){
      this.$router.push({path:"/business/index",query: {id: row.id,step:3}})
    },
    //查看保函详情
    handleDetail(row){
      let id = row.id
      getLetterDetail(id).then(res=>{
        if(res.code==200){
          this.$nextTick(() => {
            this.$refs.orderDetailModal.init(res.data)
          })
        }
      })
    },

    handleDownload(row){
      this.downloadLoading = true
      let id = row.id
      getLetterFile(id).then(res=>{
        if(res.code==200){
          this.downloadLoading = false
          window.location.href = "/profile"+res.msg
        }else{
          this.downloadLoading = false
          this.$message.error(res.msg);
          return;
        }
      }).catch(()=>{
        this.downloadLoading = false
      })
    },

    handleSizeChange(e){

      this.queryForm.pageSize = e
      this.getList()

    },

    handleQuery(){
      this.getList()

    },
    resetQuery(){

      this.queryForm = {
        pageNum:1,
        pageSize:10,
      }
      this.getList()

    },
    handleInvoice(row){
      this.showInvoiceApplyForm = true
      let id = row.id
      getLetterDetail(id).then(res=>{
        if(res.code===200){
          this.$nextTick(() => {
            this.$refs.invoiceApplyForm.init(res.data)
          })
        }
      })

    },
    handleInvoiceDetail(row){

      this.showInvoiceDetailModal = true
      let id = row.id
      getLetterDetail(id).then(res=>{
        if(res.code===200){
          this.$nextTick(() => {
            this.$refs.invoiceDetailModal.init(res.data)
          })
        }
      })
    }



  },

}
</script>


<style scoped>

.uc-order .list .item{

  font-size: 14px;

}

</style>

