<template>
	<div>
		<ucHead :indexNumber="0"></ucHead>
		<section class="uc-register">
			<div class="uc-content">
				<img src="@/assets/images/register-logo.png" class="logo" alt="">
				<div class="box">
					<h2>你好，欢迎注册</h2>
					<ul class="form">
						<li>
							<div class="txt"><img src="@/assets/images/register-icon-1.png" class="uc-icon16" alt="">手机号</div>
							<div class="con">
                <input type="text" class="uc-input" placeholder="请输入手机号码" value="" v-model="registerForm.phone" placeholder-class="uc-phColor" />
              </div>
						</li>
						<li>
							<div class="txt"><img src="@/assets/images/register-icon-2.png" class="uc-icon16" alt="">验证码</div>
							<div class="con">
                <input type="text" class="uc-input" placeholder="输入手机验证码" value="" placeholder-class="uc-phColor" v-model="registerForm.smsCode" />
                <div class="btn-yzm" id="captcha" @click="sendSmsCode" v-loading="loading" >{{yzm}}</div>
							</div>
						</li>
						<li>
							<div class="txt"><img src="@/assets/images/register-icon-2.png" class="uc-icon16" alt="">验证码</div>
							<div class="con">
                <input type="text" class="uc-input" placeholder="请输入右侧的算术结果" value="" placeholder-class="uc-phColor" v-model="registerForm.captchaCode" />
                <div class="btn-code">
                  <img :src="codeUrl" @click="getCode" class="login-code-img"/>
								</div>
							</div>
						</li>
						<li>
							<div class="txt"><img src="@/assets/images/register-icon-3.png" class="uc-icon16" alt="">设置密码</div>
							<div class="con">
                <input type="password" class="uc-input" placeholder="设置8-16位登录密码" value="" placeholder-class="uc-phColor" v-model="registerForm.password" />
              </div>
						</li>
						<li>
							<div class="txt"><img src="@/assets/images/register-icon-3.png" class="uc-icon16" alt="">设置密码</div>
							<div class="con">
                <input type="password" class="uc-input" placeholder="请重复输入登录密码" value="" placeholder-class="uc-phColor" v-model="registerForm.rePassword"  />
              </div>
						</li>
					</ul>
					<div class="operate">
						<button class="uc-btn coffee" hover-class="none" @click="registerSubmit" v-loading="registerLoading" :disabled="btnDisabled">注册</button>
						<div class="txt">已有账号，<router-link class="uc-coffee" to="/login">立即登录</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<ucFoot></ucFoot>
	</div>
</template>
<script>
import {getCodeImg, register, sendSMSRegisterCode} from "@/api/login";

export default {
		name: 'register',
		components: {},
		data() {
			return {
        codeUrl: "",
        yzm: '获取验证码',
        yzmSending: false,
        loading:false,
        sendCodeStatus:true,
        registerLoading:false,
        registerForm:{
          phone:"",
          smsCode:"",
          captchaUuid:"",
          captchaCode:"",
          password:"",
          rePassword:""
        },
        btnDisabled:false
			}
		},
  created() {
    this.getCode();
  },
    methods: {
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.registerForm.captchaUuid = res.data.uuid;
        }
      });
    },

    sendSmsCode(){

      if(this.registerForm.phone=="" || this.registerForm.phone.length!=11){
        this.$message.error("请输入手机号码！");
        return false;
      }


      if(!this.loading && !this.yzmSending){

        let _this = this

        initGeetest4({
          captchaId: 'b67b32cc7a29cedd6aed1f4042149a0f',
          product:"bind",
          nativeButton:{
            width:"200px",
            height:"100px"
          }
        },function (captcha) {
          // captcha为验证码实例
          // captcha.appendTo("#test");// 调用appendTo将验证码插入到页的某一个元素中，这个元素用户可以自定义
          captcha.onReady(function(){

            _this.loading = true

            //your code
          }).onSuccess(function(){
            //your code
            var result = captcha.getValidate();

            let postdata = {
              'phone':_this.registerForm.phone,
              'captchaOutput':result.captcha_output,
              'genTime':result.gen_time,
              'lotNumber':result.lot_number,
              'passToken':result.pass_token
            }
            sendSMSRegisterCode(postdata).then(res=>{
              if(res.code==200){
                _this.loading = false
                _this.$message.success("验证码发送成功！")
                _this.btnYzm()
              }else {
                _this.$message.error("验证码发送失败！")
                _this.loading = false
                return false
              }
              // console.log(res)
            }).catch(()=>{

              _this.loading = false

            })


          }).onError(function(){
            //your code
            _this.loading = false

          }).onClose(function (){
            _this.loading = false
          })
          captcha.showCaptcha();

        });
      }

    },






    btnYzm() {
      const _this = this;
      let i = 59;
      let int;
      if (!_this.yzmSending) {
        _this.yzmSending = true

        function clock() {
          _this.yzm = "重新获取(" + i + ")"
          i--;
          if (i < 0) {
            _this.yzmSending = false
            i = 59;
            _this.yzm = "获取验证码"
            clearInterval(int);
          }
        }
        int = setInterval(clock, 1000);
        return false;
      }
    },

    registerSubmit(){

      if(this.registerForm.phone=="" || this.registerForm.phone.length!=11){
        this.$message.error("请输入手机号码！");
        return false;
      }
      if(this.registerForm.smsCode==""){
        this.$message.error("请输入手机验证码！");
        return false;
      }
      if(this.registerForm.captchaCode==""){
        this.$message.error("请输入算术验证码！");
        return false;
      }
      if(this.registerForm.password==""){
        this.$message.error("请输入密码！");
        return false;
      }

      if(this.registerForm.password.length<8){
        this.$message.error("密码长度不能低于8位！");
        return false;
      }

      if(this.registerForm.rePassword==""){
        this.$message.error("请输入重复密码！");
        return false;
      }
      if(this.registerForm.password!=this.registerForm.rePassword){
        this.$message.error("2次密码输入不一致！");
        return false;
      }

      this.registerLoading = true
      this.btnDisabled  = true
      register(this.registerForm).then(res=>{

        if(res.code==200){

          this.$message.success("注册成功，请登录！");
          this.$router.push({ path: this.redirect || "/login" }).catch(()=>{});
          this.registerLoading = false
          this.btnDisabled  = false
          return
        }else{
          this.$message.error(res.msg)
          this.getCode()
          this.registerLoading = false
          this.btnDisabled  = false

        }

      }).catch(()=>{

        this.getCode()
        this.registerLoading = false
        this.btnDisabled  = false

      })


    }


  },
	}
</script>
