import { login, logout, getInfo,getPerfectStatus } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    isLogin:0,
    perfectStatus:0
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_IS_LOGIN:(state, isLogin) => {
      state.isLogin = isLogin
    },
    SET_PERFECT_STATUS:(state, perfectStatus)=>{
      state.perfectStatus = perfectStatus
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const account = userInfo.account
      const password = userInfo.password
      const captchaCode = userInfo.captchaCode
      const captchaUuid = userInfo.captchaUuid
      return new Promise((resolve, reject) => {
        login(account, password, captchaCode,captchaUuid).then(res => {
          setToken(res.data.token)
          commit('SET_TOKEN', res.data.token)
          commit('SET_IS_LOGIN',1)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },


    GetPerfectStatus({ commit, state }) {
      return new Promise((resolve, reject) => {
        getPerfectStatus().then(res => {
          // commit('SET_NAME', user.phone)
          commit('SET_PERFECT_STATUS',res.data.status)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },



    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          const user = res.data
          // console.log(user)
          commit('SET_NAME', user.companyName)
          // commit('SET_PERFECT_STATUS',user.perfectStatus)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
