<template>
	<div>
		<ucHead></ucHead>
		<section class="uc-business">
			<div class="uc-content">
				<div class="g-crumb">
					<img src="@/assets/images/icon-addr.png" class="uc-icon24" alt="">
					您现在位置： <router-link class="item" to="">网站首页</router-link>
					<router-link class="item" to="">业务办理</router-link>
				</div>
				<div class="main">
					<section class="main-left">
						<ul class="g-sideMenu">
							<li>
								<router-link class="item on" to="">我的信息</router-link>
							</li>
							<li>
								<router-link class="item" to="">企业信息</router-link>
							</li>
							<li>
								<router-link class="item" to="">我的订单</router-link>
							</li>
							<li>
								<router-link class="item" to="">业务办理</router-link>
							</li>
							<li>
								<router-link class="item" to="">我的订单</router-link>
							</li>
							<li>
								<router-link class="item" to="">我的保函/保单</router-link>
							</li>
							<li>
								<router-link class="item" to="">第三方订单</router-link>
							</li>
							<li>
								<router-link class="item" to="">退出登录</router-link>
							</li>
						</ul>
					</section>
					<section class="main-right">
						<el-steps :active="active" finish-status="success">
							<el-step title="录入项目信息"></el-step>
							<el-step title="选择保证人/保险人"></el-step>
							<el-step title="补充资料"></el-step>
							<el-step title="支付保费"></el-step>
						</el-steps>
						<el-form ref="form" :model="form" label-width="200px" class="uc-mt20 form-box">
							<el-form-item label="业务类型">
								<el-radio-group v-model="form.resource">
									<el-radio label="投标"></el-radio>
									<el-radio label="履约"></el-radio>
									<el-radio label="农民工工资支持"></el-radio>
									<el-radio label="质量"></el-radio>
									<el-radio label="业主支付"></el-radio>
									<el-radio label="低价差额"></el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="选择项目" required>
								<el-button type="primary" round>点击选择项目</el-button>
							</el-form-item>
							<el-form-item label="项目编号">
								<el-input v-model="form.name" placeholder="请输入项目编号"></el-input>
							</el-form-item>
							<el-form-item label="项目名称" required>
								<el-input v-model="form.name" placeholder="请输入项目名称"></el-input>
							</el-form-item>
							<el-form-item label="标段编号">
								<el-input v-model="form.name" placeholder="请输入标段编号"></el-input>
							</el-form-item>
							<el-form-item label="标段名称">
								<el-input v-model="form.name" placeholder="请输入标段名称"></el-input>
							</el-form-item>
							<el-form-item label="受益人" required>
								<el-input v-model="form.name" placeholder="请输入受益人"></el-input>
							</el-form-item>
							<el-form-item label="受益人社会信用代码证号" required>
								<el-input v-model="form.name" placeholder="请输入受益人社会信用代码证号"></el-input>
							</el-form-item>
							<el-form-item label="项目所在地">
								<!-- <el-input v-model="form.name" placeholder="请输入项目所在地"></el-input> -->
								<el-cascader v-model="area" :options="areaList" :props="optionProps" filterable ref="myCascader" placeholder="请输入项目所在地"></el-cascader>
							</el-form-item>
							<el-form-item label="项目类别" required>
								<el-select v-model="form.region" placeholder="请选择项目类别">
									<el-option label="公共项目" value="shanghai"></el-option>
									<el-option label="公共项目2" value="beijing"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="生效日期" required>
								<el-date-picker class="uc-w202" type="date" placeholder="选择生效日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
							</el-form-item>
							<el-form-item label="生效时间" required>
								<el-time-picker class="uc-w202" placeholder="选择生效时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
							</el-form-item>
							<el-form-item label="截止日期" required>
								<el-date-picker class="uc-w202" type="date" placeholder="选择截止日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
							</el-form-item>
							<el-form-item label="保证额度（元）" required>
								<el-input v-model="form.name" placeholder="请输入保证额度（元）"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" class="uc-w200" @click="submitForm('ruleForm')">提 交</el-button>
								<el-button @click="resetForm('ruleForm')"> 清 空</el-button>
							</el-form-item>
						</el-form>
					</section>
				</div>
			</div>
		</section>
		<ucFoot></ucFoot>
	</div>
</template>
<script>
export default {
	name: 'login',
	components: {},
	data() {
		return {
			active: 0,
			form: {
				name: '',
				region: '',
				date1: '',
				date2: '',
				delivery: false,
				type: [],
				resource: '',
				desc: ''
			},
			areaList: [],
			area: [],
			optionProps: {
				value: 'code',
				label: 'name',
				children: 'sub'
			},
		}
	},
	mounted() {
			// this.$refs.myCascader[0].inputValue
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
	}
	</script>
